@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter", sans-serif;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

.h1 {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2rem;
}

.p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.label {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
}

.input,
.select,
.textarea {
  display: block;
  width: 100%;
  padding: 0.875rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.input.bank-select {
  padding-right: 2rem;
}

.input.location {
  padding-right: 1.8rem;
}

.input.error,
.select.error,
.textarea.error {
  border: 1px solid #ef9aa8;
}

input:hover,
select:hover,
textarea:hover,
input:focus,
select:focus,
textarea:focus,
input:active,
select:active,
textarea:active,
input:focus-visible,
select:focus-visible,
textarea:focus-visible {
  border: 1px solid #e34b31 !important;
}

.select {
  padding-right: 2.4rem;
}

.select.alternate {
  background: url(../images/arrow-down3.svg) 97% center no-repeat !important;
}

select {
  background: url(../images/arrow-down.svg) 95% center no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input,
select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type="checkbox"] {
  accent-color: #e34b31;
}

.sort-select {
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.375rem 0.875rem;
  padding-right: 1.5rem;
  border-radius: 50px;
  background: url(../images/arrow-down2.svg) 89% center no-repeat;
  background-color: #edeff2;
  width: 4.813rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-select {
  background: url(../images/arrow-down3.svg) 90% center no-repeat !important;
  width: 7.25rem !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 0.375rem 1.759rem 0.375rem 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
}

.phone-number-select {
  background: url(../images/arrow-down.svg) 90% center no-repeat !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px 0px 0px 8px;
  padding: 0.625rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.phone-number-input {
  padding: 0.625rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px 8px 8px 0px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.weight-select {
  background: url(../images/arrow-down3.svg) 88% center no-repeat !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px 8px 8px 0px;
  padding: 0.625rem 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.weight-input {
  padding: 0.625rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px 0px 0px 8px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.875rem 1rem;
  width: 100%;
  border-radius: 12px;
}

.button-text {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.button-small {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 10px;
  padding: 0.5rem 1.125rem;
  width: fit-content;
}

.button-primary {
  padding: 0.5rem 1rem;
  width: 100%;
}

.button-secondary {
  background: none;
}

.button-inverted {
  border: 1px dashed #e6a699;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
}

.button-add {
  display: flex;
  justify-content: center;
  align-items: center;
}

button:disabled {
  background: #f6f7f8;
  color: rgba(0, 0, 0, 0.38);
}

.privacy-policy {
  font-size: 0.625rem;
  line-height: 0.875rem;
}

.onboarding-radio {
  width: 100%;
  border: 1px solid #b0b5c4;
  border-radius: 10px;
  padding: 1.063rem 1.25rem 1.063rem 1rem;
}

.onboarding-radio.checked {
  background: #f4d9d3;
  border: 1px solid #b43f28;
}

nav.main {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

nav.main.alt {
  border-bottom: none;
}

.progress-bar {
  width: 100%;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  height: 0.5rem;
}

.progress {
  height: 100%;
  background: #e53451;
  border-radius: 10px;
}

.progress-step {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 1rem;
  padding-right: 1.625rem;
}

.drag-drop {
  background: #f8f9fc;
  border: 1px dashed rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.drag-drop.error {
  border: 1px dashed red;
}

.business-owner-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.status {
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.875rem;
}

.company-info {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 1rem;
}

.warehouse-item {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

th {
  padding: 1.375rem 1rem 0.625rem;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: start;
}

th.checkbox {
  padding-right: 0.656rem !important;
  padding-left: 0.656rem !important;
}

thead:not(.rdp-head),
td:not(.rdp-cell) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

/* react-toastify */
.Toastify__toast--success {
  background-color: #80CBA6 !important;
  font-weight: 700 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  color: white !important;
  font-family: "Inter", sans-serif !important;
}

.Toastify__toast--error {
  background-color: #ef9aa8 !important;
  font-weight: 700 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  color: white !important;
  font-family: "Inter", sans-serif !important;
}

.icon-progress-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon-progress-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.icon-progress-item:not(.icon-progress-item:first-child)::before {
  position: absolute;
  content: "";
  border-bottom: 4px solid #edeff2;
  width: 100%;
  top: 50%;
  left: -50%;
  z-index: 2;
}

.icon-progress-item:not(.icon-progress-item:last-child)::after {
  position: absolute;
  content: "";
  border-bottom: 4px solid #edeff2;
  width: 100%;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.icon-progress-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.icon-progress-item.active {
  font-weight: bold;
}

.icon-progress-item.completed .step-counter {
  background-color: #e34b31;
}

.icon-progress-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 4px solid #e34b31;
  width: 100%;
  top: 50%;
  left: 50%;
  z-index: 3;
}

/* .icon-progress-item:first-child::before {
  content: none;
}

.icon-progress-item:last-child::after {
  content: none;
} */
